import React from "react";

import '../assets/styles/index.scss';
import '../assets/styles/common.scss';
import '../assets/styles/captcha.scss';

import '../assets/styles/login.scss';
import '../assets/styles/register.scss';
import '../assets/styles/authToken.scss';
import '../assets/styles/table.scss';
import '../assets/styles/footer.scss';

import Navbar from './Navbar'
import FooterSmall from './FooterSmall'

const Layout = ({ dark,bgimage, stickyFooter, children }) => {
  let style = bgimage ? {
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    backgroundImage:
              "url('https://images.unsplash.com/photo-1610306127887-7408768f6c7f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80')",
    
  } : {
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat"
  }
  
  return (
    <>
      <noscript>Please enable the Javascript on your browser.</noscript>
      <Navbar transparent />
      
      <main>
        <section className={`absolute w-full ${stickyFooter ? 'h-full': ''}`} style={{background:'#090b0c'}}>
          <div
            className={dark ? `top-0 w-full ${stickyFooter ? 'h-full' : ''}`: `absolute top-0 w-full ${stickyFooter ? 'h-full' : ''}`}
            style={style}
          >
            {children}
          </div>

      
        <FooterSmall absolute={stickyFooter ? true: false}/>
        </section>
      </main>
    </>
  )
};

export default Layout;
